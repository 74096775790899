import { addDays, endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const CART_MIN_ORDER_VALUE = 30;

//orders purchased after this time can't be delivered in the same day
export const NEXT_DAY_DELIVERY_HOUR_LIMIT = 9;

//delivery days of the week - Tuesday, Wednesday, Thursday, Friday
export const DELIVERY_DAYS = [1, 2, 3, 4, 5]; //these are

//They don't do delivery in these days. If a subscriptions falls
export const HOLIDAYS_DATES = [
  //matariki
  '2022-06-24',
  //Mourning day for QEII x 2
  '2022-09-26',
  '2022-09-27',
  //labour day
  '2022-10-24',
  '2022-10-25',
  //stop too many pre-christmas orders.
  '2022-12-19',
  '2022-12-20',
  '2022-12-21',
  '2022-12-22',
  '2022-12-23',
  //christmas boxing day
  '2022-12-24',
  '2022-12-25',
  '2022-12-26',
  '2022-12-27',
  '2022-12-28',
  //blackout days
  '2022-12-29',
  '2022-12-30',
  '2022-12-31',
  //new year's day
  '2023-01-01',
  '2023-01-02',
  '2023-01-03',
  '2023-01-04',
  //blackout days
  '2023-01-05',
  '2023-01-06',
  '2023-01-07',
  '2023-01-08',
  //auckland aniversary
  '2023-01-30',
  //waitangi day
  '2023-02-06',
  '2023-02-07',
  //easter good friday
  '2023-04-07',
  '2023-04-08',
  '2023-04-09',
  '2023-04-10',
  '2023-04-11',
  //anzac day
  '2023-04-25',
  '2023-04-26',
  //King's birthday
  '2023-06-05',
  '2023-06-06',
  //Matariki
  '2023-07-14',
  //labour day
  '2023-10-23',
  '2023-10-24',
  // New Block Out Dates - Xmas 2023
  '2023-12-18',
  '2023-12-19',
  '2023-12-20',
  '2023-12-21',
  '2023-12-22',
  //Christmas day
  '2023-12-25',
  //boxing day
  '2023-12-26',
  // Business Holidays - 2023 - 2024
  '2023-12-27',
  '2023-12-28',
  '2023-12-29',
  '2023-12-30',
  '2023-12-31',
  '2024-01-01',
  '2024-01-02',
  '2024-01-03',
  '2024-01-04',
  '2024-01-05',
  '2024-03-29',
  '2024-03-30',
  '2024-03-31',
  '2024-04-01',
  '2024-04-02',
  '2024-04-03',
  '2024-04-04',
  '2024-04-05',
  '2024-04-25',
  '2024-04-26',
  '2024-06-28',
  '2024-10-28',
  '2024-10-29',

  // 2024-2025 Christmas holiday period.
  '2024-12-25',
  '2024-12-26',
  '2024-12-27',
  '2024-12-30',
  '2024-12-31',
  '2025-01-01',
  '2025-01-02',
  '2025-01-03',
];
//when creating a subscription we need to add it as an order 72 hours before,
export const DAYS_BEFORE_DELIVERY_DATE_SUBSCRIPTION_CREATE = 3;

const CLOSE_PERIOD_DATES = {
  // Closes - The first day that the store is closed, marking the start of the closed period.
  // Subscription orders that would be **processed** from this day onwards until the end of the period will be skipped.
  // No payment will be taken for these skipped subscriptions.
  // manual offset of the DAYS_BEFORE_DELIVERY_DATE_SUBSCRIPTION_CREATE value required here.
  closes: '2024-12-17',
  // opensAfter - Last day of the store's closed period (inclusive).
  // used to determin "next shipping date on the front end for subscriptions."
  // So far it has always been DAYS_BEFORE_DELIVERY_DATE_SUBSCRIPTION_CREATE days before the resume day.
  opensAfter: '2025-01-10',
  // deliveriesResume - The day deliveries will resume after the closed period.
  // The delivery day processing logic is NOT required here; it is accounted for internally.
  // If you set 2024-04-08, subscriptions with a delivery date of April 8, 2024, or beyond will resume processing.
  deliveriesResume: '2025-01-13',
};

export const CLOSE_PERIOD = {
  closes: zonedTimeToUtc(CLOSE_PERIOD_DATES.closes, 'Pacific/Auckland'),
  opens: endOfDay(zonedTimeToUtc(CLOSE_PERIOD_DATES.opensAfter, 'Pacific/Auckland')),
  processing: addDays(
    zonedTimeToUtc(CLOSE_PERIOD_DATES.deliveriesResume, 'Pacific/Auckland'),
    -DAYS_BEFORE_DELIVERY_DATE_SUBSCRIPTION_CREATE
  ),
};
